import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule} from '@angular/common/http';
import { LoginModule } from './login/login.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { AuthguradServiceService } from './shared/services/authgurad-service.service';
import { SidenavModule } from './sidenav/sidenav.module';
import { environment } from 'src/environments/environment';
import { MatIconModule } from '@angular/material/icon';

const Socket_Url= environment['SOCKET_ENDPOINT'];
const config: SocketIoConfig = { url: Socket_Url, options: {} }

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    AppRoutingModule,
    HttpClientModule,
    LoginModule,
    SidenavModule,
    ToastrModule.forRoot(), 
    BrowserAnimationsModule,
    SocketIoModule.forRoot(config)
  ],
  providers: [AuthguradServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
