
<div class="main-wrapper account-wrapper">
    <div class="account-page">
        <div class="account-center">
            <div style="background-color:#E5F3FE;border:1.5px solid #009EFB;border-radius:17px;" class="account-box">
                <form class="form-signin" (ngSubmit)="userlogin()">
                    <div class="account-logo">
                        <h3 style="color:#009EFB">MANAGER</h3>
                    </div>
                    <div  class="form-group">
                        <label style="color:#009EFB;font-weight:400;">Username <span class="text-danger">*</span></label>
                        <input name="username" type="text" autofocus="" class="form-control" [(ngModel)]="loginForm.username" required>
                    </div>
                    <div class="form-group">
                        <label style="color:#009EFB;font-weight:400;">Password <span class="text-danger">*</span></label>
                        <input name="password" [type]="hide ? 'password':'text'" class="form-control" [(ngModel)]="loginForm.password" required>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input (change)="hide = !hide" type="checkbox" name="checkbox" ><span style="color:#009EFB;"> show password</span>
                        </label>
                    </div>
                   
                    <div class="text-center mt-4">
                        <button type="submit" class="btn btn-primary account-btn">Login</button>
                    </div>
                   
                </form>
            </div>
        </div>
    </div>
</div>
