import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { ApiserviceService } from '../shared/services/apiservice.service';
import { NotifierService } from '../shared/services/notifier.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  username:any;
  usertoken:any;
  token:any;
  role:any;
  managerId:any;
  countData:any;
  loader:boolean=false;
  Notify:{heading:any,message:any}={heading: '',message: ''};

  constructor(private router: Router,private socket: Socket,private notifierService:NotifierService,public httpClient:ApiserviceService,public _location: Location) 
  {
    this.usertoken=sessionStorage.getItem('usertoken');
    this.managerId=sessionStorage.getItem('managerId');
    this.token={token:this.usertoken};
   }

  ngOnInit(): void {
    this.role = sessionStorage.getItem('role');
    this.socket.emit('pendingTransactions',this.token);
    
     this.socket.on('pending-transaction', (function (data: any) {  
       
      if(data.success){
       this.countData = data.data.filter((item) => {
          return item.managerId == this.managerId;
       });
      
      }
      else{
       this.notifierService.showNotification(data.message+' !','X');
       this.socket.removeAllListeners('pending-transaction');
      }
     }).bind(this));

     this.socket.on('logout-device', (function (data: any) {       
      if(data.expired_token==sessionStorage.getItem('usertoken'))
      { 
        this.notifierService.showNotification(data.message+' !','X');
        this.logout();
      }
     }).bind(this));

     this.socketDisconnect();
     this.getUsrName();
  }

  getpadding(){
    var url = this.router.url
    if(url==='/home'){
      return '0px';
    }
  }

  getUsrName(){
    this.httpClient.Get('getDetail/').subscribe((response:any) => { 
      if(response.success){ 
         this.username=response.data.doc.username; 
      }
      else{
        this.notifierService.showNotification(response.message+' !','X');
      }
    });
   
  }

  

  socketDisconnect(){
    this.socket.on('disconnect', (function (data: any) {    
     this.socketConnect();
     }).bind(this));
  }  

  socketConnect(){
    this.socket.emit('connected');
  }  

  refresh(): void {
    this.router.navigateByUrl("/refresh", { skipLocationChange: true }).then(() => {
      // console.log(decodeURI(this._location.path()));
      this.router.navigate([decodeURI(this._location.path())]);
    });
  }

  sendNotification(){
    this.loader=true;
    if(this.Notify.heading && this.Notify.message){
      const data={heading:this.Notify.heading,message:this.Notify.message}
   
      this.httpClient.Post('alluserNotification/',data).subscribe((response:any) => {
   
       if(response.success){
        this.notifierService.showNotification(response.message+' !','X');
        setTimeout(()=>{ document.getElementById('notificationModel').click(); this.loader=false; },1000);
         
       }
       else{
          this.notifierService.showNotification(response.message+' !','X');
       }
     });
      
    }else if(!this.Notify.heading){
      this.notifierService.showNotification('heading is required'+' !','X');
      this.loader=false;
    }else{
      this.notifierService.showNotification('message is required' +' !','X');
      this.loader=false;
    }
    
  }

  logout()
  {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

}
