import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthenticationGuard}  from './shared/guards/authentication.guard';
import { SidenavComponent } from './sidenav/sidenav.component';

const routes: Routes = [
  { path: '',	redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'login/:token', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
 
  {path: '',component:SidenavComponent,
  children:[
    { path: 'sidenav',canActivate:[AuthenticationGuard], loadChildren: () => import('./sidenav/sidenav.module').then(m => m.SidenavModule) },
    // { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
    { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
    { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
    { path: 'user',canActivate:[AuthenticationGuard], loadChildren: () => import('./user-list/user-list.module').then(m => m.UserListModule) },
    { path: 'transcation',canActivate:[AuthenticationGuard], loadChildren: () => import('./transaction-list/transaction-list.module').then(m => m.TransactionListModule) },
    { path: 'transcation/:type',canActivate:[AuthenticationGuard], loadChildren: () => import('./transaction-list/transaction-list.module').then(m => m.TransactionListModule) },
    { path: 'site',canActivate:[AuthenticationGuard], loadChildren: () => import('./site/site.module').then(m => m.SiteModule) },
    { path: 'payment',canActivate:[AuthenticationGuard], loadChildren: () => import('./payment-list/payment-list.module').then(m => m.PaymentListModule) },
    { path: 'user-transaction/:id',canActivate:[AuthenticationGuard], loadChildren: () => import('./user-transaction/user-transaction.module').then(m => m.UserTransactionModule) },
    { path: 'idReq',canActivate:[AuthenticationGuard], loadChildren: () => import('./id-req/id-req.module').then(m => m.IdReqModule) },
    { path: 'usr_Sites/:id',canActivate:[AuthenticationGuard], loadChildren: () => import('./usr-sites/usr-sites.module').then(m => m.UsrSitesModule) },
    { path: 'usr_Sites_trans/:id',canActivate:[AuthenticationGuard], loadChildren: () => import('./usr-sites-trans/usr-sites-trans.module').then(m => m.UsrSitesTransModule) },
    { path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
    { path: 'partner', loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule) },
    { path: 'withdrawl_method',canActivate:[AuthenticationGuard], loadChildren: () => import('./withdrawl-method/withdrawl-method.module').then(m => m.WithdrawlMethodModule) },
    // { path: 'withdrawl_report',canActivate:[AuthenticationGuard], loadChildren: () => import('./withdrawl-report/withdrawl-report.module').then(m => m.WithdrawReportModule) },
    { path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule) },
    { path: 'refrel-user', loadChildren: () => import('./refrel-user/refrel-user.module').then(m => m.RefrelUserModule) },
    { path: 'withdraw-report', loadChildren: () => import('./withdraw-report/withdraw-report.module').then(m => m.WithdrawReportModule) },
   
     ]},
  { path: 'error-pageNotFound', loadChildren: () => import('./error-page-not-found/error-page-not-found.module').then(m => m.ErrorPageNotFoundModule) },
 
  
 
  
 
  {
    path: '**',
    redirectTo: 'error-pageNotFound'
  }
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
