<div class="main-wrapper">
    <div class="header">
        <div class="header-left">
            <a routerLink="/user" class="logo">
                <span>{{username}}</span>
            </a>
        </div>
        <a id="toggle_btn" href="javascript:void(0);"><i class="fa fa-bars"></i></a>
        <a id="mobile_btn" class="mobile_btn float-left" href="#sidebar"><i class="fa fa-bars"></i></a>
        <ul class="nav user-menu float-right">
            <li id="refrsh" style="padding-top:13px;" class="dropdown-toggle nav-link user-link" (click)="refresh()" data-toggle="dropdown">
                <span style="color:white;font-size:18px;cursor:pointer;"><i class="fa fa-refresh" aria-hidden="true"></i></span>
            </li>
            <li class="nav-item dropdown has-arrow">
                <a href="#" class="dropdown-toggle nav-link user-link" data-toggle="dropdown">
                    <span class="user-img">
                        <img class="rounded-circle" src="assets/img/user.jpg" width="24" alt="Admin">
                        <span class="status online"></span>
                    </span>
                    <span>&nbsp;{{username}}</span>
                </a>

                <div class="dropdown-menu">
                    <a class="dropdown-item" data-toggle="modal" data-target="#notificationModel">All User Notification</a>
                    <a class="dropdown-item" (click)="logout()">Logout</a>
                </div>
            </li>
        </ul>

        <div style="margin-right:62px;margin-top:-15px;" (click)="refresh();" class="dropdown mobile-user-menu float-right">
            <li style="padding-top:13px;" class="dropdown-toggle nav-link user-link">
                <span style="color:white;font-size:18px;cursor:pointer;"><i class="fa fa-refresh" aria-hidden="true"></i></span>
            </li>
            
        </div>
       
        <div class="dropdown mobile-user-menu float-right">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
            <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" data-toggle="modal" data-target="#notificationModel">All User Notification</a>
                <a class="dropdown-item" (click)="logout()">Logout</a>
            </div>
        </div>
        
    </div>
    <div class="sidebar" id="sidebar">
        <div class="sidebar-inner slimscroll">
            <div id="sidebar-menu" class="sidebar-menu">
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/home"><i class="fa fa-home"></i> <span>Home</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/dashboard"><i class="fa fa-dashboard"></i> <span>Dashboard</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/user"><i class="fa fa-user"></i> <span>User</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/site" ><i class="fa fa-globe"></i> <span>SitePanel</span></a>
                    </li>
                    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                        true}">
                        <a routerLink="/transcation"><i class="fa fa-exchange"></i> <span>Transaction&nbsp;&nbsp;<span *ngIf="countData" class="badge badge-pill bg-danger float-right">{{countData[0]?.Pending}}</span></span></a>
                    </li>
                    <li routerLinkActive="active"><a routerLink="/transcation/deposit">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Manage Deposits&nbsp;&nbsp;<span *ngIf="countData" class="badge badge-pill bg-danger float-right">{{countData[0]?.Deposit}}</span></span></a></li>
                    <li routerLinkActive="active"><a routerLink="/transcation/withdraw">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Withdraw Req.&nbsp;&nbsp;<span *ngIf="countData" class="badge badge-pill bg-danger float-right">{{countData[0]?.Withdrawan}}</span></span></a></li>
                   
                    <li routerLinkActive="active">
                        <a routerLink="/payment"><i class="fa fa-credit-card"></i> <span>Payment</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/idReq"><i class="fa fa-id-card"></i> <span>IdReq.&nbsp;&nbsp;<span *ngIf="countData" class="badge badge-pill bg-danger float-right">{{countData[0].Sites}}</span></span></a>
                    </li>
                    <li routerLinkActive="active" *ngIf="role != 'Partner'">
                        <a routerLink="/partner"><i class="fa fa-user"></i> <span>Partner</span></a>
                    </li>
                    <li routerLinkActive="active" *ngIf="role != 'Partner'">
                        <a routerLink="/withdrawl_method"><i class="fa fa-credit-card"></i> <span>Withdraw Method</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/withdraw-report"><i class="fa fa-credit-card"></i> <span>Withdraw Report</span></a>
                    </li>
                    <li routerLinkActive="active" *ngIf="role != 'Partner'">
                        <a routerLink="/report"><i class="fa fa-credit-card"></i> <span>Report</span></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="content" [ngStyle]="{'padding':getpadding()}">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>

<!--notification  Model -->
<div id="notificationModel" class="modal fade delete-modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body" style="border-radius: 5px;">
                <div class="form-group">
                    <label>Heading <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" name="heading" placeholder="Heading" [(ngModel)]="Notify.heading" required>
                </div>

                <div class="form-group">
                    <label>Message <span class="text-danger">*</span></label>
                    <textarea rows="5" cols="5" class="form-control" name="message" placeholder="Enter message here" [(ngModel)]="Notify.message"></textarea>
                </div>

                <div class="m-t-20 text-center"> <a href="#" class="btn btn-white" data-dismiss="modal">Close</a>
                    <button [disabled]="loader" type="submit" (click)="sendNotification();" class="btn ml-2 btn-danger">Send</button>
                </div>
            </div>
        </div>
    </div>
    
</div>

