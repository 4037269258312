import { NgModule } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatTableModule} from '@angular/material/table';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatChipsModule} from '@angular/material/chips';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

const materialComponents = [MatIconModule,MatToolbarModule,MatListModule,MatTableModule,MatPaginatorModule,MatFormFieldModule,MatInputModule,MatSnackBarModule,MatProgressSpinnerModule,MatDatepickerModule,MatNativeDateModule,MatButtonModule,ReactiveFormsModule,MatMenuModule,MatDialogModule,MatSelectModule,MatChipsModule,MatButtonToggleModule];

@NgModule({
  declarations: [],
  imports: [
    materialComponents,
  ],
  exports: [materialComponents]
})
export class MaterialModule { }
