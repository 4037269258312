// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // live
  domain: "https://acepunt.kushubmedia.com/",
  imageUrl:"https://acepunt.kushubmedia.com/uploads/",
  SOCKET_ENDPOINT: "https://acepunt.kushubmedia.com/"

  // staging
  // domain: "http://178.62.77.178:4005/",
  // imageUrl:"http://178.62.77.178:4005/uploads/",
  // SOCKET_ENDPOINT: "http://178.62.77.178:4005/"



  // domain: "http://137.184.199.153:4006/",
  // imageUrl:"http://137.184.199.153:4006/uploads/",
  // SOCKET_ENDPOINT: "http://137.184.199.153:4006/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
