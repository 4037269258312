import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {
  url: any;
  usertoken: any;
  testurl:any;
  baseUrl =  'http://178.62.77.178:3006/api/';
  basernUrl =  'https://rnapi.paisaexch.com/api/';
  constructor(private httpClient: HttpClient) {
    this.url = `${environment.domain}`;
  }

  Post178(type,Data){
    return this.httpClient.post(this.baseUrl+type,Data);
  }
  Postrn178(type,Data){
    return this.httpClient.post(this.baseUrl+type,Data);
  }
  Post(action, post) {
    this.usertoken = sessionStorage.getItem('usertoken');
    var role = sessionStorage.getItem('role');
    if (role == 'Partner') {
      return this.httpClient.post(this.url+'partner/' + "" + action + this.usertoken, post);
    } else{
      return this.httpClient.post(this.url+'subadmin/' + "" + action + this.usertoken, post);
    }
    // return this.httpClient.post(this.url+ "" + action + this.usertoken, post);
  }
  Login(action, post) {
    var role = sessionStorage.getItem('role');
    if (role == 'Partner') {
      return this.httpClient.post(this.url+'partner/' + "" + action, post);
    } else{
      return this.httpClient.post(this.url+'subadmin/' + "" + action, post);
    }
    // return this.httpClient.post(this.url + "" + action, post);
  }
  Put(action, put) {
    var role = sessionStorage.getItem('role');
    this.usertoken = sessionStorage.getItem('usertoken');
    if (role == 'Partner') {
      return this.httpClient.put(this.url+'partner/' + action + this.usertoken, put);
    } else{
      return this.httpClient.put(this.url+'subadmin/' + action + this.usertoken, put);
    }
    // return this.httpClient.put(this.url + action + this.usertoken, put);
  }

  getNoToken(action) {
    var role = sessionStorage.getItem('role');
    this.usertoken = sessionStorage.getItem('usertoken');
    if (role == 'Partner') {
      return this.httpClient.get(this.url+'partner/' + action); 
    } else{
      return this.httpClient.get(this.url+'subadmin/' + action);
    }
    // return this.httpClient.get(this.url + action);
  }
  Get(action) {
    var role = sessionStorage.getItem('role');
    this.usertoken = sessionStorage.getItem('usertoken');
    if (role == 'Partner') {
      return this.httpClient.get(this.url+'partner/' + action + this.usertoken); 
    } else{
      return this.httpClient.get(this.url+'subadmin/' + action + this.usertoken);
    }
    // return this.httpClient.get(this.url + action + this.usertoken);
  }

  Delete(action, del) {
    var role = sessionStorage.getItem('role');
    this.usertoken = sessionStorage.getItem('usertoken');
    if (role == 'Partner') { 
      return this.httpClient.put(this.url +'partner/' + action + this.usertoken, del)
    } else{
      return this.httpClient.put(this.url+'subadmin/' + action + this.usertoken, del)
    }
    // return this.httpClient.put(this.url + action + this.usertoken, del);
  }

  PostAll(action, post) {
    var role = sessionStorage.getItem('role');
    this.usertoken = sessionStorage.getItem('usertoken');
    if (role == 'Partner') { 
      return this.httpClient.post(this.url +'partner/' + "" + action, post)
    } else{
      return this.httpClient.post(this.url+'subadmin/' + "" + action, post)
    }
    // return this.httpClient.post(this.url + "" + action, post);
  }


}
