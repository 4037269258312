import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { ApiserviceService } from '../shared/services/apiservice.service';
import { NotifierService } from '../shared/services/notifier.service';
// import { NotifierService } from '../shared/services/notifier.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: {
    username: string,
    password: string,
  } = {
      username: '',
      password: '',

    };
  paramToken: any;
  hide: boolean = true;
  status: any = true;

  constructor(private router: Router, private socket: Socket, private notifierService: NotifierService, private activatdRoute: ActivatedRoute, public httpClient: ApiserviceService) {
    this.activatdRoute.paramMap.subscribe(param => {
      this.paramToken = param.get('token');

      if (this.paramToken) {
        sessionStorage.setItem('usertoken', this.paramToken);
        this.getUsrName();
      }
    });
  }

  ngOnInit(): void {
  }

  userlogin() {
    if (this.loginForm.username && this.loginForm.password) {
      const logindata = { username: this.loginForm.username, password: this.loginForm.password };
      this.socket.emit('subadminLogin', logindata);
      this.socket.on('get-message-success', (function (data: any) {
        console.log(data);
        if (data.success) {
          if (data.data.doc.role === "Partner") {
            console.log('partner');
            sessionStorage.setItem('usertoken', data.data.token);
            sessionStorage.setItem('managerId', data.data.doc.managerId);
            sessionStorage.setItem('username', data.data.doc.username);
            sessionStorage.setItem('manager', data.data.manager);
            sessionStorage.setItem('role', data.data.doc.role);
            sessionStorage.setItem('SoundStatus', this.status);
            this.router.navigate(['home']);
          } else {
            console.log(data);
            sessionStorage.setItem('usertoken', data.data.token);
            sessionStorage.setItem('managerId', data.data.doc._id);
            sessionStorage.setItem('username', data.data.doc.username);
            sessionStorage.setItem('manager', data.data.doc.username);
            sessionStorage.setItem('role', data.data.doc.role);
            sessionStorage.setItem('phone', data.data.doc.phone);
            sessionStorage.setItem('SoundStatus', this.status);
            this.router.navigate(['home']);
          }
        }
        else {
          this.notifierService.showNotification(data.message + ' !', 'X');
          this.socket.removeAllListeners('get-message-success');
        }
      }).bind(this));

    }
    else {
      if (!this.loginForm.username) {
        this.notifierService.showNotification('username is reqiured' + ' !', 'X');
      } else if (!this.loginForm.password) {
        this.notifierService.showNotification('password is reqiured' + ' !', 'X');
      }

    }


  }

  getUsrName() {
    console.log('response');
    this.httpClient.Get('getDetail/').subscribe((response: any) => {
      console.log(response);
      if (response.success) {
        if (response.data.doc.role === "Partner") {
          console.log('partner');
          sessionStorage.setItem('managerId', response.data.doc.managerId);
          sessionStorage.setItem('username', response.data.doc.username);
          sessionStorage.setItem('manager', response.data.manager);
          sessionStorage.setItem('role', response.data.doc.role);
          sessionStorage.setItem('SoundStatus', this.status);
          this.router.navigate(['home']);
        } else {
          sessionStorage.setItem('managerId', response.data.doc._id);
          sessionStorage.setItem('username', response.data.doc.username);
          sessionStorage.setItem('manager', response.data.doc.username);
          sessionStorage.setItem('phone', response.data.doc.phone);
          sessionStorage.setItem('role', response.data.doc.role);
          sessionStorage.setItem('SoundStatus', this.status);
          this.router.navigate(['home']);
        }
        // sessionStorage.setItem('managerId', response.data.doc._id);
        // sessionStorage.setItem('username', response.data.doc.username);
        // sessionStorage.setItem('manager', response.data.doc.username);
        // sessionStorage.setItem('phone', response.data.doc.phone);
        // sessionStorage.setItem('role', response.data.doc.role);
        // this.router.navigate(['home']);
      }
      else {
        this.notifierService.showNotification(response.message + ' !', 'X');
      }
    });
  }
  ngOnDestroy() {
    this.socket.removeAllListeners('get-message-success');
  }

}
